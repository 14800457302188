import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { AuthService } from '../../shared/services/auth/auth.service';
import { StorageService } from '../../shared/services/storage/storage.service';

export const authGuard: CanActivateFn = (_, state) => {
  const authService = inject(AuthService);
  const storageService = inject(StorageService);

  if (authService.isAuthenticated()) { return true; }

  storageService.set('rederict_uri', state.url);

  authService.authorize();
  return false;
};
